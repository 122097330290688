import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TextWithImage from "../components/textWithImage"
import HighlightedText from "../components/highlightedText"
import BoxedText from "../components/boxedText"
import CallToAction from "../components/callToAction"
import Intro from "../components/intro"
import Header from "../components/header"
import Footer from "../components/footer"

const AboutPage = ({ data: { page } }) => {
  return (
    <Layout>
      <SEO title={page.title} />
      <Header title={page.title} image={page.heroImage.url} />
      <Intro title={page.subtitle} />
      {page.content.map(section => (
        <section key={section.id}>
          {section.__typename === "DatoCmsTextWithImage" && (
            <TextWithImage section={section} />
          )}
          {section.__typename === "DatoCmsHighlightedText" && (
            <HighlightedText section={section} />
          )}
          {section.__typename === "DatoCmsBoxedText" && (
            <BoxedText section={section} />
          )}
        </section>
      ))}
      <CallToAction cta={page.callToAction} />
      <Footer />
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query AboutPageQuery {
    page: datoCmsAboutPage {
      title
      subtitle
      heroImage {
        url
      }
      callToAction {
        image {
          url
        }
        text
        url
        callToAction
      }
      content {
        ... on DatoCmsTextWithImage {
          id
          title
          text
          image {
            url
          }
        }
        ... on DatoCmsHighlightedText {
          id
          text
          title
          backgroundImage {
            url
          }
        }
        ... on DatoCmsBoxedText {
          id
          text
          title
        }
      }
    }
  }
`
