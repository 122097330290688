import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import Container from "./container"

const BoxedText = ({ section }) => (
  <Wrapper>
    <Container>
      <Box>
        <h4>"{section.title}"</h4>
        <div dangerouslySetInnerHTML={{ __html: section.text }} />
      </Box>
    </Container>
  </Wrapper>
)
const Wrapper = styled.div`
  @media screen and (min-width: 769px) {
    padding: 6rem 0;
  }
`

const Box = styled.div`
  padding: 3rem ${DesignTokens.spacing[5]};
  border: 1px solid ${DesignTokens.colors.black};
  border-bottom: 6px solid ${DesignTokens.colors.primary[500]};
  h4 {
    color: ${DesignTokens.colors.primary[500]};
    font-size: ${DesignTokens.fontSizes[7]};
    font-weight: 900;
    margin-bottom: ${DesignTokens.spacing[5]};
    @media screen and (min-width: 769px) {
      font-size: ${DesignTokens.fontSizes[8]};
      line-height: 1.1;
    }
  }
  p:last-child {
    margin-bottom: 0;
  }
  @media screen and (min-width: 769px) {
    padding: 3rem;
  }
`

export default BoxedText
